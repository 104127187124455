<template>
  <div class="image-rotate-wrapper">
    <canvas ref="canvas"
            :width="imgMaxWidth"
            :height="imgMaxHeight"
            :style="{width: '100%', 'height': src ? 'auto' : '1px' }">
    </canvas>
    <b-button variant="primary"
              v-if="src"
              @click="rotateClockwise"
              title="Bild drehen"
              class="btn-icon rotate-btn">
      <CIcon name="cil-crop-rotate" size="xl"/>
    </b-button>
  </div>
</template>

<script>
  export default {
    name: "ImageRotate",
    props: {
      src: {
        default: ''
      },
      imgMaxWidth: {
        default: 1920
      },
      imgMaxHeight: {
        default: 1080
      },
    },
    data: function () {
      return {
        ctx: null,
        canvas: null,
        image: null,
        angleInDegrees: 0,
      }
    },
    watch: {
      src: function(to, from) {
        this.image.src = to;
        this.angleInDegrees = 0;
      }
    },
    mounted() {
      let self = this;
      self.canvas = self.$refs.canvas;
      self.ctx = self.canvas.getContext("2d");
      self.image = document.createElement("img");
      self.image.setAttribute('crossorigin', 'anonymous');
      self.image.onload = function(){
        self.drawImageFit();
      }
      self.image.src = self.src;
    },
    methods: {
      rotateClockwise (){
        this.angleInDegrees += 90;
        this.drawRotated();
      },
      drawRotated(){
        if(this.angleInDegrees === 360){
          this.angleInDegrees = 0;
        }
        this.ctx.clearRect(0,0, this.canvas.width, this.canvas.height);
        this.ctx.save();
        this.ctx.translate(this.canvas.width * 0.5, this.canvas.height * 0.5);
        this.ctx.rotate(this.angleInDegrees * Math.PI / 180);
        this.ctx.translate(-this.canvas.width * 0.5, -this.canvas.height * 0.5);
        this.drawImageFit();
        this.ctx.restore();
      },
      drawImageFit() {
        // fit image into canvas area on orientation change
        let imgWidth = this.image.width;
        let imgHeight = this.image.height;
        if(this.angleInDegrees === 90 || this.angleInDegrees === 270){
          imgWidth = this.image.height;
          imgHeight = this.image.width;
        }
        let scale = Math.min(this.canvas.width / imgWidth, this.canvas.height / imgHeight);
        let x = (this.canvas.width / 2) - (this.image.width / 2) * scale;
        let y = (this.canvas.height / 2) - (this.image.height / 2) * scale;
        this.ctx.clearRect(0,0, this.canvas.width, this.canvas.height);
        this.ctx.drawImage(this.image, x, y, this.image.width * scale, this.image.height * scale);
      },
      getImage(crop = false) {
        return new Promise((resolve, reject) => {
          if(this.angleInDegrees === 0){
            // only return image on rotate
            resolve(false);
            return;
          }
          if(crop){
            // crop whitespace before save
            this.cropImage();
          }
          // return blob for uploads
          this.canvas.toBlob(function (blob){
            resolve(blob);
          });
        });
      },
      cropImage() {
        let w = this.canvas.width;
        let h = this.canvas.height;
        let pix = {x:[], y:[]};
        let imageData = this.ctx.getImageData(0,0,this.canvas.width,this.canvas.height);
        let x, y, index;

        for (y = 0; y < h; y++) {
          for (x = 0; x < w; x++) {
            index = (y * w + x) * 4;
            if (imageData.data[index+3] > 0) {
              pix.x.push(x);
              pix.y.push(y);
            }
          }
        }
        pix.x.sort(function(a,b){return a-b});
        pix.y.sort(function(a,b){return a-b});
        let n = pix.x.length-1;

        w = 1 + pix.x[n] - pix.x[0];
        h = 1 + pix.y[n] - pix.y[0];
        let cut = this.ctx.getImageData(pix.x[0], pix.y[0], w, h);

        this.canvas.width = w;
        this.canvas.height = h;
        this.ctx.putImageData(cut, 0, 0);
      }
    }
  }
</script>

<style scoped>
.image-rotate-wrapper {
  position: relative;
}
.image-rotate-wrapper .rotate-btn {
  position: absolute;
  right: 15px;
  top: 0;
}
</style>
